import React from 'react';
import {
    MDBRow,
    MDBCol,
    MDBContainer,
  } from 'mdbreact';
import { client } from '../api/contentful';
import { withRouter } from "react-router";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentful: {},
            error: false
        };
    }

    componentDidMount() {
        this.getContentful();
    }

    getContentful = () => {
        client.getEntries({
            'fields.url': this.props.match.params.url,
            'content_type': 'page'
          })
        .then(entry => {
            if (entry.items[0]) {
                this.setState({
                    contentful: entry.items[0]
                });
            } else {
                this.setState({
                    error: true
                })
            }
            console.log(this.state);
        })
        .catch(err => console.log(err));
    }

  

    render() {
        let content;
        let header;

        if (this.state.contentful.fields) {
            let body = documentToReactComponents(this.state.contentful.fields.content);
            content = <>
                <h1>{this.state.contentful.fields.title}</h1>
                {body}
            </>

            if (this.state.contentful.fields.showHeader == true) {
                content = <>
                    {body}
                </>

                let headerColor = '00C2CB';

                if (this.state.contentful.fields.headerColour){
                    headerColor = this.state.contentful.fields.headerColour;
                }

                header = <MDBContainer 
                    id='hero-banner' 
                    fluid 
                    style={{
                        backgroundColor: `#${headerColor}`
                    }}
                >
                    <MDBContainer >
                        <MDBRow className="py-5" >
                            <MDBCol sm="6" className="pb-5">
                                <h1>{this.state.contentful.fields.title}</h1> 
                            </MDBCol>
                            
                            <MDBCol sm="2">
                            </MDBCol>

                            <MDBCol sm="4" className='bg-white pt-2 pb-3 startd-form' style={{height: 'fit-content'}}>
                                <SearchBox />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </MDBContainer>
            }

        } else if (this.state.error == true) {
            content = <>
                <h1>404</h1>    
                <p>Nope</p>
            </>
        }

        return (
            <>
                {header}
                <MDBContainer fluid>
                    <MDBContainer >
                        <MDBRow className="py-5" >
                            <MDBCol sm="12" className="py-5">
                                {content}
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </MDBContainer>
            </>
        );
    }
  }
  
export default withRouter(Page);