import ContactForm from '../component/contact-form';
import React from 'react';
import {
    MDBRow,
    MDBCol,
    MDBContainer,
  } from 'mdbreact';


class Contact extends React.Component {


     render() {

        return (
            <MDBContainer fluid>
                <MDBContainer >
                    <MDBRow className="py-5" >
                        <MDBCol sm="8" className="py-5">
                            <h1>Contact Us</h1>
                            <ContactForm />
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBContainer>
        );
    }
  }
  
export default Contact;