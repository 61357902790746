// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help

import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { MDBInput, MDBBtn } from "mdbreact";

function ContactForm() {
  const [state, handleSubmit] = useForm("xyylggpk");
  if (state.succeeded) {
      return <p>Thanks for your message, we will be in touch!</p>;
  }
  return (
      <form onSubmit={handleSubmit}>
      <MDBInput label="Business name" size="lg"  id='name' type='text' name='name'/>
      <ValidationError 
        prefix="Name" 
        field="name"
        errors={state.errors}
      />
      <MDBInput label="Email Address" size="lg"  id='email' type='email' name='email'/>
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      <MDBInput type="textarea" label="Tell us a about your business" rows="5" id="message" name="message" />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
      <MDBBtn type="submit" disabled={state.submitting} className='w-100'>
        Submit
      </MDBBtn>
    </form>
  );
}
      
export default ContactForm;