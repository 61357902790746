import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBFormInline,
    MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon
    } from "mdbreact";
import { client } from '../api/contentful';

export default class Footer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'column1': [],
            'column2': []
        }
    }

    componentDidMount() {
        this.getFooterLinks();
    }

    getFooterLinks = () => {
        client
        .getEntries({
            'content_type': 'footerLink'
          })
        .then(entries => {
            let column1 = entries.items.filter(entry => entry.fields.column == 1);
            column1 = column1.sort((a, b) => a.fields.order - b.fields.order);
            let column2 = entries.items.filter(entry => entry.fields.column == 2);
            column2 = column2.sort((a, b) =>  a.fields.order - b.fields.order);

            this.setState({
                'column1': column1,
                'column2': column2
            })
        })
        .catch(err => console.log(err));
    }

    render() {
        let column1 = this.state.column1.map(tag => (  
            <li className="list-unstyled">
                <a href={tag.fields.url}>{tag.fields.label}</a>
            </li>
        ));

        let column2 = this.state.column2.map(tag => (  
            <li className="list-unstyled">
                <a href={tag.fields.url}>{tag.fields.label}</a>
            </li>
        ));

        return <MDBFooter color="primary-color" className="font-small pt-4 mt-4">
            <MDBContainer fluid className="text-left text-sm-center">
              <MDBRow>
                <MDBCol size="6" sm="3" className='social'>
                    <a href='https://www.facebook.com/GetsortdUK' target="_blank"><MDBIcon fab icon="facebook-square" className='mr-2' /></a>
                    <a href='https://www.instagram.com/getsortduk/' target='_blank'><MDBIcon fab icon="instagram" className='mr-2' /></a>
                </MDBCol>
                <MDBCol size="6" sm="3">
                    <ul>
                        <a href='/'>About Get Sort'd</a>
                    </ul>
                </MDBCol>
                <MDBCol size="6" sm="3">
                    <ul>
                        {column1}
                    </ul>
                </MDBCol>
                <MDBCol size="6" sm="3">
                    <ul>
                        {column2}
                    </ul>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            <div className="footer-copyright text-center py-3">
              <MDBContainer fluid>
                &copy; {new Date().getFullYear()} Copyright: <a href="https://www.getsortd.co.uk"> GetSortd.co.uk </a>
              </MDBContainer>
            </div>
          </MDBFooter>
    }
}
