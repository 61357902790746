import jwt_decode from "jwt-decode"
import Cookies from 'js-cookie';
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBSticky, MDBStickyContent, MDBContainer
    } from "mdbreact";

export default class Header extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <MDBContainer>
            <MDBNavbar color="default-color" style={{
                position: 'fixed',
                width: '100%',
                top: 0,
                left: 0,
                zIndex: 1000
            }}>
                <MDBNavbarBrand>
                    <strong><Link to="/" className="white-text header-logo">Get Sort'<span>d</span></Link></strong>
                </MDBNavbarBrand>
            </MDBNavbar>
            </MDBContainer>
        )
    }
}
