
import React from 'react';
import {
    MDBRow,
    MDBCol,
    MDBContainer,
    MDBListGroup,
    MDBListGroupItem,
  } from 'mdbreact';
import { ReactComponent as Step1 } from '../assets/step-1.svg';
import { ReactComponent as Step2 } from '../assets/step-2.svg';
import { ReactComponent as Step3 } from '../assets/step-3.svg';
import { client } from '../api/contentful';
import ContactForm from '../component/contact-form';

class Home extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mobileBackground: '',
            desktopBackground: '',
            bannerText: '',
            bannerTextText: ''
        };
    }

    componentDidMount() {
        this.getContentful();
        this.setBackground();
        window.addEventListener('resize', this.setBackground);
    }

    getContentful = () => {
        client
        .getEntry("5AkE5tNSrDkhuuv5NrtCPh")
        .then(entry => {
            this.setState({
                mobileBackground: "https:" + entry.fields.mobileBackground.fields.file.url,
                desktopBackground: "https:" + entry.fields.desktopBackground.fields.file.url,
                bannerText: "https:" + entry.fields.bannerText.fields.file.url,
                bannerTextText: entry.fields.bannerText.fields.title,
                copyText: entry.fields.copy
            });
            this.setBackground();
        }
        )
        .catch(err => console.log(err));
    }

    setBackground = () => {
        if (document.getElementById('hero-banner')) {
            if (window.innerWidth > 600) {
                document.getElementById('hero-banner').style.backgroundImage = `url('${this.state.desktopBackground}')`;
            } else {
                document.getElementById('hero-banner').style.backgroundImage = `url('${this.state.mobileBackground}')`;
            }
        }
    }

    render() {
        return (
            <div>
                <MDBContainer 
                    className='bg-light'
                    id='hero-banner' 
                    fluid 
                    style={{
                        backgroundImage: "url(" + this.state.homepageBanner + ")",
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                    desktop-banner={this.state.homepageBanner}
                    mobile-banner={this.state.mobileBanner}
                >
                    <MDBContainer >
                        <MDBRow className="py-5" >
                            <MDBCol sm="6" className="pb-5">
                                <img src={this.state.bannerText} alt={this.state.bannerTextText} className='w-100'/>   
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </MDBContainer>
                <MDBContainer fluid>
                    <MDBContainer>
                        <MDBRow className="py-4">
                            <MDBCol sm="12" className="py-3">
                                <p style={{whiteSpace: 'pre-wrap'}}>{this.state.copyText}</p>
                                <p>For more information, head to our <a href='/vendors-faq'>FAQ page.</a></p>
                                <h2 className="text-center mt-5 mb-4">Register your interest</h2>
                                <ContactForm />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="py-4 home-type">
                            <MDBCol sm="6" className="py-3 how-it-works">
                                <h2 className='text-center mb-5'>How it works?</h2>
                                <MDBListGroup>
                                    <MDBListGroupItem style={{border: 'none'}}><Step1 style={{float: 'left', width: '70', height: '70'}} className='mr-3'/><span>Sign up & Get Sort’D will build your storefront and list your product/services for free. </span></MDBListGroupItem>
                                    <MDBListGroupItem style={{border: 'none'}}><Step2 style={{float: 'left', width: '70', height: '70'}} className='mr-3'/><span>Get Sort’D will advertise and drive qualified customers to your new storefront where they can browse, order and checkout.</span></MDBListGroupItem>
                                    <MDBListGroupItem style={{border: 'none'}}><Step3 style={{float: 'left', width: '70', height: '70'}} className='mr-3' /><span>We connect you to your new customers and you will arrange delivery/collection of their order.</span></MDBListGroupItem>
                                </MDBListGroup>
                            </MDBCol>
                            <MDBCol sm="6" className="py-3">
                                <h2 className="text-center mb-4">Type of suppliers</h2>
                                <MDBContainer fluid>
                                    <MDBRow fluid>
                                        <MDBCol size="4" className='mb-3'>
                                            <img src="https://storage.googleapis.com/get-sortd.appspot.com/icons/cake-green.svg" className="my-1 img-fluid z-depth-1 rounded-circle" alt="Birthday Party" />
                                            <p className='text-center w-100 mt-2'>Cakes</p>
                                        </MDBCol>
                                        <MDBCol size="4">
                                            <img src="https://storage.googleapis.com/get-sortd.appspot.com/icons/balloons-red.svg" className="my-1 img-fluid z-depth-1 rounded-circle" alt="Christening" />
                                            <p className='text-center w-100 mt-2'>Balloons</p>
                                        </MDBCol>
                                        <MDBCol size="4">
                                            <img src="https://storage.googleapis.com/get-sortd.appspot.com/icons/flowers-green.svg" className="my-1 img-fluid z-depth-1 rounded-circle" alt="Wedding" />
                                            <p className='text-center w-100 mt-2'>Florists</p>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow fluid className='mb-3'>
                                        <MDBCol size="4">
                                            <img src="https://storage.googleapis.com/get-sortd.appspot.com/icons/bouncy-castle-red.svg" className="my-1 img-fluid z-depth-1 rounded-circle" alt="Engagement" />
                                            <p className='text-center w-100 mt-2'>Bouncy Castles</p>
                                        </MDBCol>
                                        <MDBCol size="4">
                                            <img src="https://storage.googleapis.com/get-sortd.appspot.com/icons/entertainer-green.svg" className="my-1 img-fluid z-depth-1 rounded-circle" alt="Party" />
                                            <p className='text-center w-100 mt-2'>Entertainers</p>
                                        </MDBCol>
                                        <MDBCol size="4">
                                            <img src="https://storage.googleapis.com/get-sortd.appspot.com/icons/catering-red.svg" className="my-1 img-fluid z-depth-1 rounded-circle" alt="Funeral" />
                                            <p className='text-center w-100 mt-2'>Caterers</p>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </MDBContainer>
            </div>
        );
    }
  }
  
export default Home;