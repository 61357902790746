import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import "./assets/scss/mdb-pro.scss"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import Home from './pages/home';
import Page from './pages/page'
import Contact from './pages/contact';
import Header from './component/header'
import Footer from './component/footer'
import React from 'react'

function App() {
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '1726700457531814');
  fbq('track', 'PageView');
  
  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <Header />
        </header>
        <div style={{
          paddingTop: '64px'
        }}>
          <Switch>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route exact path="/:url">
              <Page />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
          </Switch>
        </div>
        <footer className="App-footer">
          <Footer />
        </footer>
    </Router>
    </div>
  );
}

export default App;
